// Constants
import { QUERY_LIMITS } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import PlacesListItem from '@/components/elements/places/PlacesListItem'
import SearchForm from '@/components/ui/SearchForm'
import VuetifyPopoverMenu from '@/components/ui/VuetifyPopoverMenu'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import VuetifyDataTable from '@/components/ui/VuetifyDataTable'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapState, mapMutations } from 'vuex'
// Services
import { getEveryPlaces } from '@/services/place'
// Utils
import { getEnvironmentVariable } from '@/utils'
import { cloneDeep, isNil, set } from 'lodash'

export default {
  name: 'PlacesList',
  components: {
    CardContainer,
    PlacesListItem,
    SearchForm,
    VuetifyDataTable,
    VuetifyPopoverMenu,
    VuetifyToolBar
  },
  mixins: [uiMixin],
  data() {
    return {
      // PopoverMenu Items"
      popoverMenuItems: [
        {
          label: 'Descargar CSV',
          icon: 'mdi-download',
          itemFn: this.handleDownloadCSV
        }
      ],
      // table
      headers: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Nombre',
          value: 'name'
        },
        {
          text: 'URL',
          value: 'url'
        },
        {
          text: 'Creado',
          value: 'createTimestamp'
        },
        { text: 'Acciones', align: 'center' }
      ],
      // Opciones actuales de filtrado (solo 'constraints'),
      // elegidas por el usuario
      currentSearchFormFilters: {},
      // Opciones por defecto de filtrado del listado
      defaultFilterOptions: {
        constraints: [['brand', '==', getEnvironmentVariable('VUE_APP_BRAND')]],
        order: {
          field: 'createTimestamp',
          direction: 'desc'
        },
        limit: QUERY_LIMITS[4]
      },
      // Others
      items: [],
      processingRequest: true,
      // search fields
      searchFields: [
        {
          field: 'url',
          label: 'Url',
          type: 'text'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice', 'searchFormFiltersPerPage']),
    /**
     * La "toolBar" es sticky
     *
     * @return {Boolean}
     */
    toolBarIsSticky() {
      return this.extraSmallDevice
    },
    /**
     * Obtengo los filtros almacenados actuales de la página
     */
    savedSearchFormFilters() {
      return !isNil(this.searchFormFiltersPerPage[this.$route.name])
        ? this.searchFormFiltersPerPage[this.$route.name]
        : []
    }
  },
  async mounted() {
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  methods: {
    ...mapMutations('app', ['setSearchFormFiltersPerPage']),
    /**
     * Ejecuta la acción indicada en el menú desplegable de acción
     *
     * @param {Number} index - actions index
     */
    handleClickMenuItem(index) {
      if (
        this.popoverMenuItems[index] &&
        typeof this.popoverMenuItems[index].itemFn === 'function'
      ) {
        this.popoverMenuItems[index].itemFn(index)
      }
    },
    /**
     * Descarga CSV de los establecimientos disponibles
     *
     * @param {number} index - indice de la acción dentro del menú
     */
    async handleDownloadCSV(index) {
      let popoverMenuItems = cloneDeep(this.popoverMenuItems)

      try {
        // Loading and disabled
        set(popoverMenuItems, `[${index}].label`, 'Generando fichero...')
        set(popoverMenuItems, `[${index}].params.disabled`, true)
        this.popoverMenuItems = popoverMenuItems
        // Lanzamos petición
        await this.getItems({
          ...this.currentSearchFormFilters,
          limit: QUERY_LIMITS[7],
          format: 'csv'
        })
      } catch (error) {
        // Mostramos error
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        // Loading and disabled
        popoverMenuItems = cloneDeep(this.popoverMenuItems)
        set(popoverMenuItems, `[${index}].label`, 'Descargar CSV')
        set(popoverMenuItems, `[${index}].params.disabled`, false)
        this.popoverMenuItems = popoverMenuItems
      }
    },
    /**
     * Acción tras recibir las opciones de búsqueda
     *
     * @param {Array} options - opciones de filtrado
     */
    handleSearch(options) {
      // Almaceno en "searchFormFiltersPerPage" las opciones de búsqueda
      this.setSearchFormFiltersPerPage({ page: this.$route.name, filters: options })
      // Establecemos los filtros actuales
      this.setCurrentSearchFormFilters(options)
      // Obtenemos los items de la tabla
      this.setItems()
    },
    /**
     * Obtenemos todos los datos necesarios para
     * la vista inicialmente
     */
    async getEveryNeededData() {
      // Establecemos los filtros actuales
      this.setCurrentSearchFormFilters(this.savedSearchFormFilters)
      // Obtenemos los establecimientos
      await this.setItems(this.currentSearchFormFilters)
    },
    /**
     * Obtenemos los items (establecimientos) pasados
     * unos parámetros de opciones
     *
     * @param {object} options - opciones (filtros) a usar
     * @return {array} - array con los resultados obtenidos
     */
    async getItems(options) {
      const items = await getEveryPlaces(options)
      return items
    },
    /**
     * Establecemos los filtros seleccionados
     *
     * @param {array} options - opciones elegidas
     */
    setCurrentSearchFormFilters(options) {
      // Prepara los "where" para la consulta
      const constraints =
        Array.isArray(options) && options.length > 0
          ? options.reduce((accConstraint, constraint) => {
              accConstraint.push([constraint.field, constraint.operator, constraint.value])
              return accConstraint
            }, cloneDeep(this.defaultFilterOptions.constraints))
          : this.defaultFilterOptions.constraints

      // Actualizamos las opciones de filtrado actuales
      this.currentSearchFormFilters = { ...this.defaultFilterOptions, constraints }
    },
    /**
     * Establecemos el array de items (para la tabla)
     */
    async setItems() {
      try {
        // Loading
        this.processingRequest = true
        // Obtenemos los clientes del establecimiento (límite de 500)
        this.items = await this.getItems(this.currentSearchFormFilters)
      } catch (error) {
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    }
  }
}
